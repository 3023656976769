import { default as indexXj2LAaDB3wMeta } from "/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/index.vue?macro=true";
export default [
  {
    name: indexXj2LAaDB3wMeta?.name ?? "index",
    path: indexXj2LAaDB3wMeta?.path ?? "/",
    meta: indexXj2LAaDB3wMeta || {},
    alias: indexXj2LAaDB3wMeta?.alias || [],
    redirect: indexXj2LAaDB3wMeta?.redirect,
    component: () => import("/Users/lion/Documents/work/outwork/website/nuxt3-template/pages/index.vue").then(m => m.default || m)
  }
]